<template>
	<div class="auth-wrapper auth-v2">
		<b-row class="auth-inner m-0">
			<!-- Brand logo-->
			<b-link class="brand-logo" to="/">
				<img class="img" src="@/assets/images/logo/logo.png" alt="">
				<h2 class="brand-text text-primary ml-1 mb-0">FruitScreen</h2>
			</b-link>
			<!-- /Brand logo-->

			<!-- Left Text-->
			<b-col
				lg="8"
				class="d-none d-lg-flex align-items-center p-5"
			>
				<div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
					<b-img fluid :src="imgUrl" :alt="$t('Register')" />
				</div>
			</b-col>
			<!-- /Left Text-->

			<!-- Register-->
			<b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
				<b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
					<b-card-title title-tag="h2" class="font-weight-bold mb-1">
						{{$t('Adventure starts here')}} 🚀
					</b-card-title>
					<b-card-text class="mb-2">
						{{$t('Make your app management easy and fun')}}!
					</b-card-text>

					<!-- form -->
					<validation-observer ref="registerForm">
						<b-form class="auth-register-form mt-2">
							<!-- nickname -->
							<b-form-group :label="$t('Nickname')" label-for="register-nickname">
								<validation-provider
									#default="{ errors }"
									:name="$t('Nickname')"
									rules="required"
								>
									<b-form-input
										id="register-nickname"
										v-model="form.nickname"
										name="register-nickname"
										:state="errors.length > 0 ? false:null"
										:placeholder="$t('Please Enter...')"
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>

							<!-- mobile -->
							<b-form-group :label="$t('Mobile')" label-for="register-mobile">
								<validation-provider
									#default="{ errors }"
									:name="$t('Mobile')"
									rules="required|mobile"
								>
									<b-form-input
										id="register-mobile"
										v-model="form.tel"
										name="register-mobile"
										:state="errors.length > 0 ? false:null"
										:placeholder="$t('Please Enter...')"
										type="tel"
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>

							<!-- code -->
							<b-form-group :label="$t('Code')" label-for="register-code">
								<validation-provider
									#default="{ errors }"
									:name="$t('Code')"
									rules="required"
								>
									<b-input-group
										class="input-group-merge"
										:class="errors.length > 0 ? 'is-invalid':null"
									>
										<b-form-input
											id="register-code"
											v-model="form.code"
											class="form-control-merge"
											type="tel"
											:state="errors.length > 0 ? false:null"
											name="register-code"
											:placeholder="$t('Please Enter...')"
										/>
										<b-input-group-append is-text>
											<a href="javascript:;"
												class="text-primary" v-if="codeSeconds==0"
												@click="getCode(form.tel)"
											>{{$t('Get Verification Code')}}</a>
											<span class="text-grey" v-else>{{codeSeconds}}s</span>
										</b-input-group-append>
									</b-input-group>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>

							<!-- password -->
							<b-form-group
								label-for="register-password"
								:label="$t('Password')"
							>
								<validation-provider
									#default="{ errors }"
									:name="$t('Password')"
									rules="required"
								>
									<b-input-group
										class="input-group-merge"
										:class="errors.length > 0 ? 'is-invalid':null"
									>
										<b-form-input
											id="register-password"
											v-model="form.password"
											class="form-control-merge"
											:type="passwordFieldType"
											:state="errors.length > 0 ? false:null"
											name="register-password"
											:placeholder="$t('Please Enter...')"
										/>
										<b-input-group-append is-text>
											<feather-icon
												:icon="passwordToggleIcon"
												class="cursor-pointer"
												@click="togglePasswordVisibility"
											/>
										</b-input-group-append>
									</b-input-group>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>

							<b-form-group>
								<b-form-checkbox
									id="register-privacy-policy"
									v-model="isAgree"
									name="checkbox-1"
								>
									{{$t('I agree to')}}
									<b-link>{{$t('privacy policy & terms')}}</b-link>
								</b-form-checkbox>
							</b-form-group>

							<b-button
								variant="primary"
								block
								type="submit"
								@click.prevent="validationForm"
							>
								{{$t('Sign up')}}
							</b-button>
						</b-form>
					</validation-observer>

					<p class="text-center mt-2">
						<span>{{$t('Already have an account')}}?</span>
						<b-link :to="{name:'login'}">
							<span>&nbsp;{{$t('Sign in')}}</span>
						</b-link>
					</p>

					<!-- divider -->
					<div class="divider my-2">
						<div class="divider-text">
							or
						</div>
					</div>

					<div class="auth-footer-btn d-flex justify-content-center">
						<b-button
							variant="facebook"
							href="javascript:void(0)"
						>
							<feather-icon icon="FacebookIcon" />
						</b-button>
						<b-button
							variant="twitter"
							href="javascript:void(0)"
						>
							<feather-icon icon="TwitterIcon" />
						</b-button>
						<b-button
							variant="google"
							href="javascript:void(0)"
						>
							<feather-icon icon="MailIcon" />
						</b-button>
						<b-button
							variant="github"
							href="javascript:void(0)"
						>
							<feather-icon icon="GithubIcon" />
						</b-button>
					</div>
				</b-col>
			</b-col>
		<!-- /Register-->
		</b-row>
	</div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver,extend,validate } from 'vee-validate'

import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
	BRow, BCol, BLink, BButton, BForm, BFormCheckbox, BFormGroup, BFormInput, BInputGroup, BInputGroupAppend, BImg, BCardTitle, BCardText,
} from 'bootstrap-vue'
import { required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { sendCaptcha } from '@/mixins/captcha'
import {mobileRegister} from '@/libs/apis/userApi'
import md5 from "blueimp-md5";

let timer = null

export default {
	components: {
		VuexyLogo,
		BRow,
		BImg,
		BCol,
		BLink,
		BButton,
		BForm,
		BCardText,
		BCardTitle,
		BFormCheckbox,
		BFormGroup,
		BFormInput,
		BInputGroup,
		BInputGroupAppend,
		// validations
		ValidationProvider,
		ValidationObserver,
	},
	mixins: [togglePasswordVisibility,sendCaptcha],
	data() {
		return {
			isAgree: false,
			sideImg: require('@/assets/images/pages/register-v2.svg'),
			// validation
			required,
			mobileVerify: `export const phone = `,
			form: {
				tel: '',
				nickname:'',
				password: '',
				code: ''
			}
		}
	},
	computed: {
		passwordToggleIcon() {
			return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
		},
		imgUrl() {
			if (this.$store.state.appConfig.layout.skin === 'dark') {
				// eslint-disable-next-line vue/no-side-effects-in-computed-properties
				this.sideImg = require('@/assets/images/pages/register-v2-dark.svg')
				return this.sideImg
			}
			return this.sideImg
		},
	},
	created () {
		this.addMobileVerify()
	},
	methods: {
		addMobileVerify () {
			extend('mobile', value => {
				return value.length==11 || this.$t('Mobile is not Valid')
			});
		},
		validationForm() {
			this.$refs.registerForm.validate().then(success => {
				if (success) {
					this.doReg()
				}
			})
		},
		doReg () {
			if(!this.isAgree){
				this.$toast.error(this.$t('Please check the Protocol'))
				return
			}
			let params = Object.assign({},this.form,{password: md5(this.form.password)})
			mobileRegister(params).then(res=>{
				this.$router.replace('/login')
			},err=>{
				this.$toast(err.message)
			})
		}
	},
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
